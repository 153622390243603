import __ from 'utils/i18n'

import alarmSound from 'assets/sounds/alert.mp3'
import alertError1 from 'assets/sounds/alert_error-01.wav'
import alertError2 from 'assets/sounds/alert_error-02.wav'
import alertError3 from 'assets/sounds/alert_error-03.wav'
import alertHighIntensity from 'assets/sounds/alert_high-intensity.wav'
import alertSimple from 'assets/sounds/alert_simple.wav'
import heroDecorativeCelebration1 from 'assets/sounds/hero_decorative-celebration-01.wav'
import heroDecorativeCelebration2 from 'assets/sounds/hero_decorative-celebration-02.wav'
import heroDecorativeCelebration3 from 'assets/sounds/hero_decorative-celebration-03.wav'
import heroSimpleCelebration1 from 'assets/sounds/hero_simple-celebration-01.wav'
import heroSimpleCelebration2 from 'assets/sounds/hero_simple-celebration-02.wav'
import heroSimpleCelebration3 from 'assets/sounds/hero_simple-celebration-03.wav'
import notificationAmbient from 'assets/sounds/notification_ambient.wav'
import notificationDecorative1 from 'assets/sounds/notification_decorative-01.wav'
import notificationDecorative2 from 'assets/sounds/notification_decorative-02.wav'
import notificationHighIntensity from 'assets/sounds/notification_high-intensity.wav'
import notificationSimple1 from 'assets/sounds/notification_simple-01.wav'
import notificationSimple2 from 'assets/sounds/notification_simple-02.wav'
import isString from 'lodash-es/isString'

export const SOUND_KEY_NO_SOUND = 'noSound'
export const SOUND_KEY_1 = 'sound1'
export const SOUND_KEY_2 = 'sound2'
export const SOUND_KEY_3 = 'sound3'
export const SOUND_KEY_4 = 'sound4'
export const SOUND_KEY_5 = 'sound5'
export const SOUND_KEY_6 = 'sound6'
export const SOUND_KEY_7 = 'sound7'
export const SOUND_KEY_8 = 'sound8'
export const SOUND_KEY_9 = 'sound9'
export const SOUND_KEY_10 = 'sound10'
export const SOUND_KEY_11 = 'sound11'
export const SOUND_KEY_12 = 'sound12'
export const SOUND_KEY_13 = 'sound13'
export const SOUND_KEY_14 = 'sound14'
export const SOUND_KEY_15 = 'sound15'
export const SOUND_KEY_16 = 'sound16'
export const SOUND_KEY_17 = 'sound17'
export const SOUND_KEY_18 = 'sound18'

class Sound {
    title: Function
    sound: HTMLMediaElement

    constructor({title, soundPath}) {
        this.title = title
        this.sound = soundPath && isString(soundPath) && new Audio(soundPath)
    }

    play() {
        if (this.isPlay) {
            this.sound.play()
        }
    }

    get isPlay() {
        return this.sound instanceof HTMLMediaElement
    }
}

export const soundMap = {
    [SOUND_KEY_NO_SOUND]: new Sound({title: () => __('No sound')}),
    [SOUND_KEY_1]: new Sound({title: () => __('Sound 1'), soundPath: alarmSound}),
    [SOUND_KEY_2]: new Sound({title: () => __('Sound 2'), soundPath: alertError1}),
    [SOUND_KEY_3]: new Sound({title: () => __('Sound 3'), soundPath: alertError2}),
    [SOUND_KEY_4]: new Sound({title: () => __('Sound 4'), soundPath: alertError3}),
    [SOUND_KEY_5]: new Sound({title: () => __('Sound 5'), soundPath: alertHighIntensity}),
    [SOUND_KEY_6]: new Sound({title: () => __('Sound 6'), soundPath: alertSimple}),
    [SOUND_KEY_7]: new Sound({
        title: () => __('Sound 7'),
        soundPath: heroDecorativeCelebration1,
    }),
    [SOUND_KEY_8]: new Sound({
        title: () => __('Sound 8'),
        soundPath: heroDecorativeCelebration2,
    }),
    [SOUND_KEY_9]: new Sound({
        title: () => __('Sound 9'),
        soundPath: heroDecorativeCelebration3,
    }),
    [SOUND_KEY_10]: new Sound({
        title: () => __('Sound 10'),
        soundPath: heroSimpleCelebration1,
    }),
    [SOUND_KEY_11]: new Sound({
        title: () => __('Sound 11'),
        soundPath: heroSimpleCelebration2,
    }),
    [SOUND_KEY_12]: new Sound({
        title: () => __('Sound 12'),
        soundPath: heroSimpleCelebration3,
    }),
    [SOUND_KEY_13]: new Sound({
        title: () => __('Sound 13'),
        soundPath: notificationAmbient,
    }),
    [SOUND_KEY_14]: new Sound({
        title: () => __('Sound 14'),
        soundPath: notificationDecorative1,
    }),
    [SOUND_KEY_15]: new Sound({
        title: () => __('Sound 15'),
        soundPath: notificationDecorative2,
    }),
    [SOUND_KEY_16]: new Sound({
        title: () => __('Sound 16'),
        soundPath: notificationHighIntensity,
    }),
    [SOUND_KEY_17]: new Sound({
        title: () => __('Sound 17'),
        soundPath: notificationSimple1,
    }),
    [SOUND_KEY_18]: new Sound({
        title: () => __('Sound 18'),
        soundPath: notificationSimple2,
    }),
}

export default soundMap
