import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import {eventSeverityTitle} from 'constants/eventSeverity'
import SoundPlaySelect from 'components/Alarms/SoundPlaySelect'
import SoundPlayOption from 'components/Alarms/SoundPlayOption'

class AlarmsSounds extends Component {
    static propTypes = {
        sounds: PropTypes.object.isRequired,
        alarmTypes: PropTypes.array,
        alarmTypeToSound: PropTypes.object,
        setAlarmSound: PropTypes.func,
    }

    get firstHalfAlarmTypes() {
        const {alarmTypes} = this.props
        return alarmTypes.slice(0, Math.round(alarmTypes.length / 2))
    }

    get secondHalfAlarmTypes() {
        const {alarmTypes} = this.props
        return alarmTypes.slice(Math.round(alarmTypes.length / 2))
    }

    handleSoundChange = ({alarmType, soundKey}) =>
        this.props.setAlarmSound({alarmType, soundKey})

    handleOnPlaySound = (soundKey) => {
        this.props.sounds[soundKey].play()
    }

    isPlaySound = (soundKey) => {
        return get(this.props.sounds, [soundKey, 'isPlay'], false)
    }

    renderSoundsOptions = (alarmType) => {
        return Object.keys(this.props.sounds).map((soundKey) => {
            const sound = this.props.sounds[soundKey]
            return (
                <SoundPlayOption
                    playSound={() => this.handleOnPlaySound(soundKey)}
                    label={sound.title()}
                    key={`${soundKey}_${alarmType}`}
                    value={soundKey}
                    isPlaySound={this.isPlaySound(soundKey)}
                />
            )
        })
    }

    renderSoundsSelections = (alarmTypes) => {
        const {alarmTypeToSound} = this.props
        return alarmTypes.map((alarmType) => (
            <SoundPlaySelect
                key={alarmType}
                defaultLabel={get(
                    this.props.sounds,
                    [alarmTypeToSound[alarmType], 'title'],
                    () => alarmType
                )()}
                onChange={(event, value) =>
                    this.handleSoundChange({alarmType, soundKey: value})
                }
                label={get(eventSeverityTitle, alarmType, () => alarmType)()}
                defaultValue={alarmTypeToSound[alarmType]}
                playSound={() => this.handleOnPlaySound(alarmTypeToSound[alarmType])}
                isPlaySound={this.isPlaySound(alarmTypeToSound[alarmType])}
            >
                {this.renderSoundsOptions(alarmType)}
            </SoundPlaySelect>
        ))
    }

    render() {
        return (
            <div className="cols">
                <div className="col">
                    {this.renderSoundsSelections(this.firstHalfAlarmTypes)}
                </div>
                <div className="col">
                    {this.renderSoundsSelections(this.secondHalfAlarmTypes)}
                </div>
            </div>
        )
    }
}

export default AlarmsSounds
